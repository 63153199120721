@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Mountains+of+Christmas:wght@400;700&display=swap');

:root {
	/* #04591a */
	--c-hsl-primary: 136, 91%, 18%;
	/* #db0202 */
	--c-hsl-secondary: 0, 98%, 43%;
	/* #D1E3E0 */
	--c-hsl-tertiary: 170, 24%, 85%;
	/* #2BA589 */
	--c-hsl-quadernary: 166, 59%, 41%;


	--c-hsl-black: 0, 0%, 0%;
	--c-hsl-white: 0, 0%, 100%;

	--c-heading: hsla(var(--c-hsl-secondary), 1.0);
	--c-overlay: hsla(var(--c-hsl-tertiary), 0.4);

	--c-X-square: hsla(var(--c-hsl-primary), 1.0);
	--c-O-square: hsla(var(--c-hsl-tertiary), 1.0);

	--font-family-serif: 'Mountains of Christmas', serif;
	--font-family-handwritten: 'Permanent Marker', cursive;
	--font-family-monospace: Consolas, monaco, monospace;

	--z-xs: -1;
	--z-sm: 1;
	--z-md: 10;
	--z-lg: 20;
	--z-xl: 30;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: min(max(1rem, 4vw), 22px);
	margin: 0;
	padding: 0;
}

body {
	color: hsl( var(--c-hsl-white) );
	font-family: var(--font-family-serif);
	height: 100%;
	margin: 0;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	overflow-y: hidden;
	padding: 0;
}

.unlist {
	list-style: none;
}

#root {
	background-image: url(https://source.unsplash.com/collection/3526289);
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	min-height: 100vh;
	padding: 1.5rem;
	position: relative;
}

#root::after {
	background-color: var(--c-overlay);
	bottom: 0;
	content: "";
	display: block;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: var(--z-sm);
}

.page {
	position: relative;
	z-index: var(--z-md);
}

.page-header {
	align-items: center;
	backdrop-filter: blur(3px) contrast(90%);
	border-radius: 8px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.24rem;
	padding: 1vh 2vw;
}

.page-heading {
	color: var(--c-heading);
	font-family: var(--font-family-serif);
	font-weight: 700;
	margin: 0;
	text-align: center;
}

.page-buttons .button:first-of-type {
	margin-right: 0.24rem;
}

.game {
	backdrop-filter: blur(3px) contrast(90%);
	border-radius: 8px;
	padding: 3vh 4vw;
}

.board {
	align-items: center;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));;
	grid-template-rows: repeat(3, 20vh);
	justify-content: center;
}

.square {
	background-color: transparent;
	border: 2px solid hsla(var(--c-hsl-black), 0.2);
	color: hsla(var(--c-hsl-primary), 1.0);
	display: block;
	font-family: var(--font-family-handwritten);
	font-size: 6rem;
	font-weight: bold;
	line-height: 3rem;
	height: 100%;
	padding: 0;
	text-align: center;
}

.color-player-X {
	color: hsla(var(--c-hsl-primary), 1.0);
}

.color-player-O {
	color: hsla(var(--c-hsl-secondary), 1.0);
}

.square--x,
.game__status--x-winner {
	color: hsla(var(--c-hsl-primary), 1.0);
}

.square:nth-of-type(1) {
	border-width: 0;
}

.square:nth-of-type(2) {
	border-width: 0 0 0 2px;
}

.square:nth-of-type(3) {
	border-width: 0 0 0 2px;
}

.square:nth-of-type(4) {
	border-width: 2px 0 0 0;
}

.square:nth-of-type(5) {
	border-width: 2px 0 0 2px;
}

.square:nth-of-type(6) {
	border-width: 2px 0 0 2px;
}

.square:nth-of-type(7) {
	border-width: 2px 0 0 0;
}

.square:nth-of-type(8) {
	border-width: 2px 0 0 2px;
}

.square:nth-of-type(9) {
	border-width: 2px 0 0 2px;
}

.square:focus {
	outline: none;
}

.kbd-navigation .square:focus {
	background-color: #ddd;
}

.game-status-info {
	font-size: 0.8rem;
	margin: 0.74rem 0 0;
	position: sticky;
}

.game-status {
	display: inline-block;
	margin-right: 2ch;
}

button,
.button,
.swal2-container button.swal2-styled {
	-webkit-appearance: none;

	align-items: center;
	background-color: hsla( var(--c-hsl-black), 0.6 );
	border: none;
	border-radius: 3px;
	color: hsl( var(--c-hsl-white) );
	display: inline-flex;
	font-family: var(--font-family-serif);
	font-weight: 700;
	letter-spacing: 0.008em;
	line-height: 1rem;
	padding: clamp(0.5vh, 0.8rem, 1vh) clamp(1vw, 1rem, 2vw);
}

.page-buttons .button {
	background-color: hsla(var(--c-hsl-primary), 1.0);
}

button:active {
	transform: translateY(1px) scale(0.99);
}

.modal {
	padding: 1rem;
}

.modal__heading {
	color: hsl( var(--c-hsl-black) );
}

.room-code {
	background-color: hsla(var(--c-hsl-black), 0.1);
	display: inline-block;
	font-family: var(--font-family-monospace);
	font-size: 110%;
	margin: 1rem 0.2rem 0;
	padding: 0.14rem 0.3rem;
}

.icon--create,
.icon--join {
	margin-left: 1ch;
}

.swal2-container.swal2-backdrop-show {
	background: hsla( var(--c-hsl-quadernary), 0.45 ) !important;
	backdrop-filter: blur(5px);
}

.swal2-container .swal2-header,
.swal2-container .swal2-content,
.swal2-container .swal2-actions {
	padding: 0;
}

.swal2-title {
	color: var(--c-heading);
}

.swal2-container .swal2-input {
	box-shadow: none;
	height: auto;
	margin: 0.5rem 0 0.25rem;
	padding: 0.5rem;
}

.swal2-styled.swal2-confirm {
	background-color: hsla( var(--c-hsl-primary), 0.8) !important;
}